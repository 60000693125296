import React, { useState } from 'react';
import axios from 'axios';
import { Link,useNavigate } from "react-router-dom";
import swal from "sweetalert";
import '../static/css/style.css';

function ResetPasswordForm() {
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const [isLoading, setIsLoading] = useState(false);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios.post('/api/reset', { token, email, password })
      .then(response => {
        setIsLoading(false);
        if(response.data.status === 200){
       
        swal("Success",response.data.message,"success");
        navigate("/login");
        }else{
            swal({
                title:"Error",
                text:"Please try again later",
                icon:"error",
              });
        }
       
      }).catch(error => {
        setIsLoading(false); // set loading state back to false
      })
      .finally(() => {
        setIsLoading(false); // set loading state back to false
      });;
  }

  return (
    <div className="form-container">
    <style>
  {`
      .form-container {
          min-height:81vh;
          padding-top: 24px;
          max-width: 500px;
          margin: 0 auto;
          text-align: center;
          padding: 30px;
      }

      input[type="text"],
      input[type="password"] {
          width: 100%;
          padding: 10px;
          margin-bottom: 20px;

      }
      `}
</style>
<br />
      <h1 className="text-5xl font-bold white">Reset password</h1>
      <br />
     <form onSubmit={handleSubmit}>
      <input type="email" id="email" required placeholder="Email"  className="input input-bordered input-info w-full max-w-xs" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <br/>
      <br/>
      
      <input type="password"required 
       pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
       title="Password must contain atleast 1 uppercase, lowercase, number and special character"
        id="password" placeholder="Password"  className="input input-bordered input-info w-full max-w-xs" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <br />
      
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      {isLoading && (
             <div className="overlay">
             <div className="spinner">{"Resetting password..."}</div>
           </div>
          )}
      <button className="btn btn-outline" type="submit">Reset</button>
    </form>
    </div>
  );
}

export default ResetPasswordForm;