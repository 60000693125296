import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Component, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config/config";
import swal from 'sweetalert';
import '../static/css/style.css';


function SignUp()  {

  

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState([]);
  const [step, setStep] = useState(1);
  const [emailCode, setEmailCode] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const navigate = useNavigate();

  const [registerInput, setRegister]= useState({
    error_list: [],
  });

  const [isLoading, setIsLoading] = useState(false);


  const handleInput = (e) => {
    e.persist();
    setRegister({...registerInput, [e.target.name]: e.target.value})
  }

  var Data = {
    name: name,
    email: email,
    phone: phone,
    password: password,
  };

  //alert(data);
   var InsertAPI = "http://localhost/api/signup.php";
  //var InsertAPI = "https://vxp7501.uta.cloud/api/signup.php";
  
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const handleSignup =  (e) => {
    e.preventDefault();
    setIsLoading(true);

      axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('api/register', Data).then(res =>{

      if (res.data.status === 200) {
        setIsLoading(false);
        localStorage.setItem('auth_token',res.data.token);
        localStorage.setItem('auth_name',res.data.username);
        swal("Success",res.data.message,"success").then(function() {
        navigate("/login");
      });;
        
       } else {
        //swal("Failre",res.data.validation_errors.email,"error")
        //setError(error => [...error,res.data.validation_errors]);
          setRegister({...registerInput, error_list:res.data.validation_errors});

      }
   
    }).catch(error => {
      setIsLoading(false); // set loading state back to false
    })
    .finally(() => {
      setIsLoading(false); // set loading state back to false
    });
  });
};



 
  return (
    <div className="form-container">
    <style>
      {`
        .form-container {
          min-height: 81vh;
          max-width: 500px;
          margin: 0 auto;
          text-align: center;
          padding: 30px;
        }
  
        input[type="text"],
        input[type="password"] {
          width: 100%;
          padding: 10px;
          margin-bottom: 20px;
        }
  
        @media (max-width: 640px) {
          .form-container {
            max-width: 100%;
            padding: 15px;
          }
        }
      `}
    </style>  

    <h1 className="text-5xl font-bold white">Create Account</h1>
    <br />
    <div className="divider"></div>
    <br />
    <form onSubmit={handleSignup}>
      <div>
        <input
          type="text"
          required
          placeholder="Please enter your Name"
          id="name"
          value={name}
          className="input input-bordered input-info w-full max-w-xs"
          onChange={(e) => setName(e.target.value)}
        />
        <span>{registerInput.error_list.name}</span>
      </div>
      <div>
        <input
          type="email"
          required
          placeholder="Username/Email ID"
          id="email"
          value={email}
          className="input input-bordered input-info w-full max-w-xs"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div >{registerInput.error_list.email}</div>
      </div>
      <br />
      <div>
        <input
          type="text"
          title="Enter a valid 10 digit phone number"
          pattern="[1-9]{1}[0-9]{9}"
          required
          placeholder="Phone No."
          id="phone"
          value={phone}
          className="input input-bordered input-info w-full max-w-xs"
          onChange={(e) => setPhone(e.target.value)}
        />
        <span>{registerInput.error_list.phone}</span>
      </div>

      <div>
        <input
          type="password"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          title="Password must contain atleast 1 uppercase, lowercase, number and special character"
          placeholder="Password"
          id="password"
          required
          minLength="6"
          maxLength="15"
          value={password}
          className="input input-bordered input-info w-full max-w-xs"
          onChange={(e) => setPassword(e.target.value)}
        />
        <span>{registerInput.error_list.password}</span>
      </div>
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text"></span>
          <input type="checkbox" required className="checkbox" />I Agree to
          all the terms and conditions of Terraazas de Guacuco.
        </label>
      </div>
      {error && <p>{error}</p>}
      {isLoading && (
         <div className="overlay">
           <div className="spinner">{"Registering... Please wait"}</div>
         </div>
      )}
      <div>
        <button className="btn btn-outline" type="submit" name="register">
              Sign Up
            </button>
          </div>
        </form>  
    </div>
  );
}

export default SignUp;
