import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, getCookie, saveCookie } from "../config/config";
import axios from "axios";
import swal from "sweetalert";
import '../static/css/style.css';


function handleNavigation(role,navigate) {
 
  
  if (role === 0) { //resident
    navigate("/resident/profile");
  }
  else if (role === 1){//manager super-admin
    navigate("/manager/home");
  }
  else if (role === 2){ //garden-manager
    navigate("/gardenmanager/home");
  }
  else if(role === 3){//pool-manager
    navigate("/poolmanager/home");
  }
  else if (role === 4){//security-manager
    navigate("/securitymanager/home");
  }
  return null;
}

function ResidentLogin() {

  const navigate = useNavigate();
  const [loginInput,setLogin] = useState({
    email:'',
    password: '',
    error_list: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    e.persist();
    setLogin({...loginInput, [e.target.name]: e.target.value});
   
  }

  const loginSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    }

    
    axios.get('/sanctum/csrf-cookie').then(response => {
      (async () => {
        const res = await axios.post('api/login', data);
        setIsLoading(false);
        if(res.data.status === 200){
          localStorage.setItem('auth_token',res.data.token);
          localStorage.setItem('auth_name',res.data.username);
    
           saveCookie({ id: res.data.id, role: res.data.role,name:res.data.username,email:res.data.email,phone:res.data.phone });
          await handleNavigation(res.data.role,navigate);
          //alert("Logged In Successfully");
          swal("Success","Logged In Successfully","success");
          //   swal("Success",res.data.message,"success").then(function() {
          //     handleNavigation(res.data.role,navigate);
          // });
    
        }
        else if(res.data.status === 401){
          swal({
            title:"Warning",
            text:"Invalid Credentials",
            icon:"warning",
          });
        }
        else if(res.data.status === 500){
          swal({
            title:"Error",
            text:"Please try again later",
            icon:"error",
          });
        }
        else {
          setLogin({...loginInput, error_list: res.data.validation_errors});
        }
      })().catch(error => {
        setIsLoading(false); // set loading state back to false
      })
      .finally(() => {
        setIsLoading(false); // set loading state back to false
      });;
    });
  }

  

  return (
    <div className="form-container">
      <style>
        {`
            .form-container {
                min-height:81vh;
                padding-top: 24px;
                max-width: 500px;
                margin: 0 auto;
                text-align: center;
                padding: 30px;
            }
    
            input[type="text"],
            input[type="password"] {
                width: 100%;
                padding: 10px;
                margin-bottom: 20px;
    
            }
            `}
      </style>

      <h1 className="text-5xl font-bold white">Welcome </h1>
      <br />
      <form onSubmit={loginSubmit}>
        <h2 className="white">Login to manage your home!</h2>
        <br />
        <div>
          <input
            type="email"
            name="email"
            required
            placeholder="Email"
            className="input input-bordered input-info w-full max-w-xs"
            value={loginInput.email}
            onChange={handleInput}
          />
          <span>{loginInput.error_list.email}</span>
        </div><br/>
        <div>
          <input
            required
            type="password"
            name="password"
            placeholder="Password"
            className="input input-bordered input-info w-full max-w-xs"
            value={loginInput.password}
            onChange={handleInput}
          />
           <span>{loginInput.error_list.password}</span>
        </div>
        {isLoading && (
             <div className="overlay">
             <div className="spinner">{"Logging in..."}</div>
           </div>
          )}
        <div>
          <button className="btn btn-outline" type="submit">
            Login
          </button>
        </div>
      </form>
      <br />
      <Link to="/forgot_password" className="link link-hover link-info">
        Forgot Password?
      </Link>
      <Link to="/signup" className="link link-hover link-info">
        {" "}
        | Sign up
      </Link>
    </div>
  );
}

export default ResidentLogin;
