import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import '../static/css/style.css';

function ForgotPassword() {

  const navigate = useNavigate();
  const [email,setEmail] = useState("");
  const [errors,setErrors] = useState([]);
  const [status,setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit =  (e) => {
    e.preventDefault();
    setIsLoading(true);

    setErrors([]);
    setStatus(null);
    
      axios.get('/sanctum/csrf-cookie').then(res => {
      axios.post('api/forgot',{email}).then(res => {
        setIsLoading(false);
        if(res.data.status === 200){
         
        swal("Success",res.data.message,"success");
      
        navigate("/login");
    } else{
      swal({
        title:"Error",
        text:"Please try again later",
        icon:"error",
      });
    }
    }).catch(error => {
      setIsLoading(false); // set loading state back to false
    })
    .finally(() => {
      setIsLoading(false); // set loading state back to false
    });;
  });

  }

  return (
    <div className="form-container">
          <style>
        {`
            .form-container {
                min-height:81vh;
                padding-top: 24px;
                max-width: 500px;
                margin: 0 auto;
                text-align: center;
                padding: 30px;
            }
    
            input[type="text"],
            input[type="password"] {
                width: 100%;
                padding: 10px;
                margin-bottom: 20px;
    
            }
            `}
      </style>
      <br />
      <h1 className="text-5xl font-bold white">Forgot password?</h1>
      <br />
      <form onSubmit={handleSubmit}>
        <h2 class="white">Let's verify you!</h2>
        <br />
        <div>
          <input
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="input input-bordered input-info w-full max-w-xs"
          />
        </div>
        <br />
        {isLoading && (
             <div className="overlay">
             <div className="spinner">{"Sending Email..."}</div>
           </div>
          )}
        <div>
          <button className="btn btn-outline" type="submit">
            Reset
          </button>
        </div>
      </form>
      <br />
      <Link to="/" className="link link-hover link-info">
        Login
      </Link>
    </div>
  );
}

export default ForgotPassword;
