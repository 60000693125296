import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import '../static/css/style.css';

function Header() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="header">
      <div className="navbar bg-base-300">
        <div className="flex-1">
          <Link to="/" className="btn btn-ghost normal-case text-xl">
            Terrazas
          </Link>
        </div>
        <div className="flex-none lg:hidden">
          <button
            className="btn btn-square btn-ghost"
            onClick={toggleMenu}
            aria-label="Menu"
          >
            <svg viewBox="0 0 24 24" className="w-6 h-6">
              <path
                className="fill-current"
                d="M4 6H20M4 12H20M4 18H20"
              />
            </svg>
          </button>
        </div>
        <div className="flex-none lg:flex">
          <ul className="menu menu-horizontal px-1">
            <li>
              <Link className="navbar-home" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="navbar-home" to="about">
                About
              </Link>
            </li>
            <li>
              <Link className="navbar-home" to="services">
                Services
              </Link>
            </li>
            <li>
              <a>
                <Link to="/login">Login</Link>
              </a>
            </li>
            <li>
              <a className="navbar-home" href="http://sxy1729.uta.cloud/">
                Blog
              </a>
            </li>
            <li>
              <Link className="navbar-home" to="contact">
                Contact
              </Link>
            </li>
            <li>
              <Link className="navbar-home" to="/visitor/home">
                Visitor
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed inset-0 z-10 bg-base-100 lg:hidden">
          <div className="p-4">
            <ul className="menu menu-vertical">
              <li>
                <Link className="navbar-home" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="navbar-home" to="about">
                  About
                </Link>
              </li>
              <li>
                <Link className="navbar-home" to="services">
                  Services
                </Link>
              </li>
              <li>
                <a>
                  <Link to="/login">Login</Link>
                </a>
              </li>
              <li>
                <a className="navbar-home" href="http://sxy1729.uta.cloud/">
                  Blog
                </a>
              </li>
              <li>
                <Link className="navbar-home" to="contact">
                  Contact
                </Link>
              </li>
              <li>
                <Link className="navbar-home" to="/visitor/home">
                  Visitor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
